import React from "react";
import { useState } from "react";
import '../pages/App.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
//import QrReader from "./qrScanner";
import QrCodeScanner from './qrCodeScanner';
import ResultContainerPlugin from './qrCodeResults';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import KeyboardIcon from '@mui/icons-material/Keyboard';

export function Search() {
  const [scanQrCode, setScanQrCode] = useState(false);
  const [decodedResults, setDecodedResults] = useState([]);
  const onNewScanResult = (decodedText, decodedResult) => {
      console.log("App [result]", decodedResult);
      setDecodedResults(prev => [...prev, decodedResult]);
  };

  function startQrScanner() {
    setScanQrCode(true);
  }

  function stopQrScanner() {
    setScanQrCode(false);
  }
  
    return (
<>
{ scanQrCode ? (
  <>
  <ResultContainerPlugin results={decodedResults} />
<QrCodeScanner
                    fps={10}
                    qrbox={250}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
                />
                <br />
                <Button
                            margin="dense"
                            variant="contained"
                            onClick={stopQrScanner}
                            startIcon={<KeyboardIcon />}
                        >
                            Return to Search
                        </Button>
</>
): null }
{ !scanQrCode ? (
  <>

<Button
                            margin="dense"
                            variant="contained"
                            onClick={startQrScanner}
                            startIcon={<QrCode2Icon />}
                        >
                            Scan Asset QR
                        </Button>
<Box
  sx={{
    display: 'flex',
    flexDirection: 'column',
  }}
  autoComplete="off"
>
  <TextField 
  fullWidth
    margin="dense"
    id="catalogueInput" 
    label="Search By Catalogue Number" 
    variant="outlined" 
    type="text" 
    onKeyDown={(e) => (
      e.keyCode === 13 ?  window.location.href='/?catNo='+ document.getElementById("catalogueInput").value : null
    )}
  />
  </Box>
<Button variant="contained" style={{minWidth: '100%'}} onClick={(e) => { window.location.href='/?catNo='+ document.getElementById("catalogueInput").value}}>Lookup Catalogue Number</Button>
<Box
sx={{
  display: 'flex',
  flexDirection: 'column',
}}
autoComplete="off"
>
<TextField 
fullWidth
  margin="dense"
  id="assetInput" 
  label="Search By Asset Number" 
  variant="outlined" 
  type="text" 
  onKeyDown={(e) => (
    e.keyCode === 13 ? window.location.href='/?assetNo='+ document.getElementById("assetInput").value : null
    )}
/>
</Box>
  <Button variant="contained" style={{minWidth: '100%'}} onClick={(e) => { window.location.href='/?assetNo='+ document.getElementById("assetInput").value}}> Lookup Asset Number  </Button>
  </>
  ): null }
</>
  )}

export default Search