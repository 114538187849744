import React, { useEffect, useState } from "react";
import { baseUrl } from '../shared';
import Login from '../components/login'
import FirstTab from "../components/tab-components/FirstTab";
import './App.css';
import Search from "../components/search";
import Edit from './edit/editAsset';
import Inspect from './edit/inspectAsset';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, TableHead } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from "moment";

const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
    var assetNoUrl = urlParams.get('assetNo')
    var catNoUrl = urlParams.get('catNo')
      console.log('Asset No: ', assetNoUrl);
      console.log('Cat No: ',catNoUrl)

function AssetView() {
  const [asset, setAsset] = useState();
  const [editing, setEditing] = useState(false);
  const [inspecting, setInspecting] = useState(false);
  const [addAttachment, setAddAttachment] = useState(false);
  var [attachments, setAttachments] = useState();
  var [inspectionHistory, setInspectionHistory] = useState();
  const [attachmentsLoaded, setAttachmentsLoaded] = useState(false);
  const [inspectionHistoryLoaded, setInspectionHistoryLoaded] = useState(false);
  const [uploadIdForDelete, setUploadIdForDelete] = useState('');


  function clearAddAttachment() {
    setAddAttachment(false);
  }

  useEffect(()=> {
    console.log('Asset ',asset);
    console.log('Edit State:',editing);
    console.log('Inspecting State ',inspecting);
    console.log('Attachments: ',attachments)
  });
  
  const lookUpAssetNo = async () => {
      const url = baseUrl + assetNoUrl;
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
      .then((response) => response.json())
      .then((data) => {
         setAsset(data[0]);
      })
      .catch((err) => {
         console.log(err.message);
      });
  };

  const lookUpCatNo = async () => {
      const url = baseUrl + 'catNo/' +catNoUrl;
      await fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
      .then((response) => response.json())
      .then((data) => {
         setAsset(data[0]);
      })
      .catch((err) => {
         console.log(err.message);
      });
  };

  useEffect(() => {
    if(catNoUrl !== null) {
      lookUpCatNo()
    }
    if(assetNoUrl !== null) {
      lookUpAssetNo()
    }
  }, []);

  function showAttachments() {
      const url = baseUrl + 'attachmentList/' + asset.catNo;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
           setAttachments(json);
           setAttachmentsLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function showInspectionHistory() {
      const url = baseUrl + 'inspectHistory/' + asset.assetId;
      fetch(url, {
        method: 'GET',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken')
        }})
        .then((res) => {
          return res.json();
      }).then((json) => {
           setInspectionHistory(json);
           setInspectionHistoryLoaded(true);
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

 
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = uploadId => {
      setUploadIdForDelete(uploadId)
      setOpen(true);
    };

  const handleClose = () => {
    setUploadIdForDelete('')
    setOpen(false);
  };

    function delAttachment() {
      const url = baseUrl + 'uploadDel';
      fetch(url, {
        method: 'POST',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken'),
            'uploadId': uploadIdForDelete
        }}) 
        .then((res) => {
          return res.json();
      }).then((json) => {
           setAttachmentsLoaded(false);
           setOpen(false)
           setUploadIdForDelete('')
      })
      .catch((err) => {
         console.log(err.message);
      });
    }

    function hideAttachments() {
      setAttachmentsLoaded(false);
    }

    function hideInspectionHistory() {
      setInspectionHistoryLoaded(false);
    }

  return (
    
    <div className="assetView">
      <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="confirmDeleteTitle">
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmDeleteDesc">
            Are you sure you want to delete the Attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={delAttachment}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
    {localStorage.getItem('username') !== null ? (
  null
): <div>
< Login />
</div>}

<br></br>

{!asset && !editing && !inspecting && localStorage.getItem('canSearch') === '1' ? (
  <div>
    < Search />
  </div>
): null}


    { asset && !editing && !inspecting ? (
      <div>  
        { asset && !addAttachment ? (
 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
        <TableBody>
          <TableRow><TableCell align="left">Catalogue Number</TableCell><TableCell align="right" className="ucase">{asset.catNo}</TableCell></TableRow>
          <TableRow><TableCell align="left">Asset Number</TableCell><TableCell align="right" className="ucase">{asset.assetId}</TableCell></TableRow>
          <TableRow><TableCell align="left">Customer</TableCell><TableCell align="right">{asset.customer}</TableCell></TableRow>
          <TableRow><TableCell align="left">Type</TableCell><TableCell align="right">{asset.type}</TableCell></TableRow>
          <TableRow><TableCell align="left">Site Location</TableCell><TableCell align="right">{asset.siteLocation}</TableCell></TableRow>
          <TableRow><TableCell align="left">Item</TableCell><TableCell align="right">{asset.item}</TableCell></TableRow>
          <TableRow><TableCell align="left">Manufacturer</TableCell><TableCell align="right">{asset.manufacturer}</TableCell></TableRow>
          <TableRow><TableCell align="left">Model</TableCell><TableCell align="right">{asset.model}</TableCell></TableRow>
          <TableRow><TableCell align="left">Serial Number</TableCell><TableCell align="right">{asset.serialNo}</TableCell></TableRow>
          <TableRow><TableCell align="left">Max Height</TableCell><TableCell align="right">{asset.maxHeight}</TableCell></TableRow>
          <TableRow><TableCell align="left">Capacity</TableCell><TableCell align="right">{asset.capacity}</TableCell></TableRow>
          <TableRow><TableCell align="left">Build Date</TableCell><TableCell align="right">{(asset.buildDate === null || asset.buildDate === '-') ? '-':moment(asset.buildDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Calibration Expiry Date</TableCell><TableCell align="right">{(asset.expiringCalibrationDate === null || asset.expiringCalibrationDate === '-') ? '-':moment(asset.expiringCalibrationDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Last Inspection Result</TableCell><TableCell align="right">{asset.inspectionStatus}</TableCell></TableRow>
          <TableRow><TableCell align="left">Last Inspection Date</TableCell><TableCell align="right">{(asset.lastInspectionDate === null || asset.lastInspectionDate === '-' ) ? '-':moment(asset.lastInspectionDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Next Inspection Due</TableCell><TableCell align="right">{(asset.nextInspectionDate === null || asset.nextInspectionDate === '-') ? '-':moment(asset.nextInspectionDate).format('DD-MM-YYYY')}</TableCell></TableRow>
          <TableRow><TableCell align="left">Comments</TableCell><TableCell align="right">{asset.inspectionReason}</TableCell></TableRow>
          </TableBody>
      </Table>
    </TableContainer>
    ):null}

{ !addAttachment && asset ? (
<div className="mb-2">
                        
                        <TableContainer component={Paper}>
                              <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">
                                <TableHead>
                                  <TableCell align="center">
                                    <h2>Inspection History{ asset && !inspectionHistoryLoaded ? ( <IconButton onClick={showInspectionHistory}><ExpandMoreIcon /></IconButton>) : (<IconButton  margin="dense" variant="contained" onClick={hideInspectionHistory}><ExpandLessIcon /></IconButton>)}</h2> 
                                  </TableCell>
                                </TableHead>
                              {asset && inspectionHistoryLoaded ? (
                              <TableBody>
                                              {inspectionHistory.map((list, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">Inspection Date: {moment(list.updatedAt).format('DD-MM-YYYY hh:mm A')} <br />Status: {list.inspectionStatus}<br /> Inspected By: {list.updatedBy}<br />Notes: {list.inspectionReason}</TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>):null}
                              </Table>
                            </TableContainer>
                              </div>
): null}

    { !addAttachment && asset ? (
  <div className="mb-2">
<TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="simple table" margin="dense">

      <TableHead>
          <TableCell align="center">
            <h2>Documents{ asset && !attachmentsLoaded ? ( <IconButton onClick={showAttachments}><ExpandMoreIcon /></IconButton>) : (<IconButton margin="dense" variant="contained" onClick={hideAttachments}><ExpandLessIcon /></IconButton>)}</h2> 
          </TableCell>
        </TableHead>
        {attachmentsLoaded ? (      <TableBody>
                      {attachments.map((list, index) => (
                        <TableRow key={index}>
                            <TableCell align="center"><a href={list.imageUrl}>{list.image_name}</a>  { localStorage.getItem('canAddAttachment') === '1' ? (<IconButton margin="dense" variant="contained" onClick = {() => {handleClickOpen(list.uploadId)}} ><Tooltip title="Delete Attachment"><CancelIcon /></Tooltip></IconButton>): null}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>):null}

      </Table>
    </TableContainer>
      </div>
): null}

  { !addAttachment && asset && localStorage.getItem('canInspectAsset') === '1' ? (
  <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                style={{minWidth: '100%'}}
                                onClick={(e) => {
                                    setInspecting(true);
                                }}
                            >
                                Inspect Asset
                            </Button>
          </div>
): null}

{ !addAttachment && asset && localStorage.getItem('canEditAsset') === '1' ? (
          <div className="mb-2"   >
                            <Button
                                margin="dense"
                                variant="contained"
                                style={{minWidth: '100%'}}
                                onClick={(e) => {
                                    setEditing(true);
                                }}
                            >
                                Edit Item
                            </Button>
          </div>
): null}
{ !addAttachment && asset && localStorage.getItem('canAddAttachment') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                style={{minWidth: '100%'}}
                                onClick={(e) => {
                                    setAddAttachment(true);
                                }}
                            >
                                Add Document
                            </Button>
                           
          </div>
): null}
 {addAttachment && asset ? (
  <div className="modalOuter">
  <div className="modalBox">
      <div className="outlet">
        <br />
  <Button variant="contained" margin="dense" sx={{ maxWidth: '25%', marginLeft: "auto" }} onClick={clearAddAttachment}>Cancel Upload</Button>
  <FirstTab  foundAsset={asset}/>
          </div>
        </div>
      </div>
 ): null}
 
{ !addAttachment && asset && localStorage.getItem('canSearch') === '1' ? (
          <div className="mb-2">
                            <Button
                                margin="dense"
                                variant="contained"
                                style={{minWidth: '100%'}}
                                onClick={(e) => {
                                    window.location.href='/'
                                }}
                            >Home
                            </Button>
          </div>
          ): null}
          

  
  </div>
    ): null}

{ !addAttachment && editing && localStorage.getItem('canEditAsset') === '1' ? (        
<Edit 
foundAsset={asset}/>
): null}

{ !addAttachment && inspecting && localStorage.getItem('canInspectAsset') === '1' ? (        
<Inspect 
foundAsset={asset}/>
): null}
<br />
<br />
  </div>
);
}
export default AssetView;