import { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { baseUrl } from "../../shared";
import Button from '@mui/material/Button';

export default function FirstTab({foundAsset}) {
  const [uploadAsset] = useState(foundAsset);

  const [file, setFile] = useState({
    selectedFile: null
  });

  const [dragActive, setDragActive] = useState(false);
  const [msg, setMsg] = useState("");

  const checkFileType = (e, eventType) => {
    let extension;

    if (eventType === "drop") {
      extension = e.dataTransfer.files[0].name.match(/\.([^.]+)$/)[1];
    } else {
      extension = e.target.value.match(/\.([^.]+)$/)[1];
    }

    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "pdf":
      case "heic":
        eventType !== "drop"
          ? setFile({ selectedFile: e.target.files[0] })
          : setFile({ selectedFile: e.dataTransfer.files[0] });
        setMsg("");
        break;
      default:
        setFile({ selectedFile: null });
        setMsg(`.${extension} format is not supported.`);
    }
  };

  const checkSize = (e, eventType) => {
    let size;
    if (eventType === "drop") {
      // console.log("size", e.dataTransfer.files[0]);
      size = e.dataTransfer.files[0].size / 8;
    } else {
      // console.log("size", e.target.files[0].size);
      size = e.target.files[0].size / 8;
    }
    // console.log("converted size", size);

    if (size <= 2048000) {
      checkFileType(e, eventType);
    } else {
      setMsg("Size should be less than 2MB");
    }
  };

  const chooseFile = (e) => {
    if (e.target.files && e.target.files[0]) {
      checkSize(e);
      // checkFileType(e);
    }
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      checkSize(e, "drop");
      // checkFileType(e, "drop");
    }
  };

  function uploadAttachment(e) {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append(
        "myFile",
        file.selectedFile
    );

    // Details of the uploaded file
    console.log('File should show here: '+file.selectedFile.name);
    const url = baseUrl + "upload";
    fetch(url, {
        method: 'POST',
        headers: {
            'username': localStorage.getItem('username'),
            'bToken': localStorage.getItem('bToken'),
            'catNo': [uploadAsset.catNo]
        },
         body: formData,
    })
        .then((response) => {
            if (!response.ok) throw new Error('something went wrong');
            return response.json();
        })
        .then((data) => {
          window.location.href='/?assetNo='+[uploadAsset.assetId]
        });
  }

  return (
    <div className="FirstTab">
      <form
        className="uploadBox"
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        onSubmit={(e) => e.preventDefault()}
      >
        {file.selectedFile !== null ? (
          <p className="filename">{file.selectedFile.name}</p>
        ) : msg !== "" ? (
          msg
        ) : (
          <FiUpload className="upload-icon" />
        )}

        <div>
          <div className="drag">
            Drop your file here or{" "}
            <div className="browse">
              <label
                htmlFor="img"
                className="file-label"
                onClick={() => document.getElementById("getFile").click()}
              >
                Browse
                <input
                  type="file"
                  data-max-size="2048"
                  id="getFile"
                  className="fileIcon"
                  onChange={chooseFile}
                />
              </label>
            </div>
          </div>
        </div>

        <p className="info">Supported files: JPEG, PNG, PDF</p>
        <br></br>
                        <div className="mb-2">
                            <Button variant="contained"
                            onClick={uploadAttachment}
                            >
                                Upload Document
                            </Button>
                        </div>
      </form>
    </div>
  );
}
